<template>
  <q-page class="flex flex-top">
    <div class="row full-width items-stretch">
      <div class="col bg-agora-light-4">
        <div class="row fit justify-end content-center items-center q-pr-xl">
          <q-img src="/statics/gan-small.png" style="height: 60px; max-width: 60px" />
          <h1 class="title q-pl-lg q-pr-xl">{{$t('global.site-name')}}</h1>
        </div>
      </div>

      <div class="col">
        <div class="row fit content-center q-pa-xl">
          <div class="full-width text-overline text-agora">{{$t('auth.login')}}</div>
          <form class="col col-8"
            @submit.prevent.stop="login"
            @reset.prevent.stop="reset"
            :disabled="pending"
          >
            <q-input v-model="formData.email" ref="email" @keyup.native="$refs['email'].resetValidation()"
              :label="$t('auth.email')" color="agora"
              :rules="[val => !!val || $t('form.required_field')]"
            >
              <template v-slot:append><q-icon name="person" /></template>
            </q-input>

            <q-input v-model="formData.password" ref="password" @keyup.native="$refs['password'].resetValidation()"
              :label="$t('auth.password')" type="password" color="agora"
              :rules="[val => !!val || $t('form.required_field')]"
            >
              <template v-slot:append><q-icon name="lock" /></template>
            </q-input>

            <div class="row reverse q-gutter-sm">
              <q-btn color="agora" class="col" size="sm" unelevated type="submit">{{$t('auth.connect')}}</q-btn>
              <q-btn color="agora" class="col" size="sm" flat to="/lost-password">{{$t('auth.lost_password')}} ?</q-btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'LoginPage',

  data () {
    return {
      formData: {
        email: '',
        password: ''
      },
      pending: false
    }
  },

  methods: {
    login () {
      this.$refs['email'].validate()
      this.$refs['password'].validate()

      if (this.$refs['email'].hasError || this.$refs['password'].hasError) {
        this.$q.notify({
          message: this.$t('form.missing_fields'),
          color: 'negative'
        })
      } else {
        this.pending = true
        this.$store.dispatch('auth/login', this.formData).then(() => {
          this.$q.notify({
            message: this.$t('auth.login_success'),
            color: 'positive'
          })
          this.$router.push('/')
        }).catch(error => {
          return this.$q.notify({
            message: this.$t(`auth.errors.${error.response.data.message}`),
            color: 'negative'
          })
        }
        ).finally(() => { this.pending = false })
      }
    },

    reset () {
      this.formData = {
        email: '',
        password: ''
      }
    }
  }
}
</script>
